import '../styles/Footer.css';
import { useEffect, useRef, useState } from 'react';

function Footer()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [FooterText1, setFooterText1] = useState('FooterText1');
    const [FooterText2, setFooterText2] = useState('FooterText2');

    useEffect(()=>{
        if(windowSize.current[0] < 600)
        {
           setFooterText1('FooterText1_Mobile');
           setFooterText2('FooterText2_Mobile');
        }

    }, windowSize.current[0])

    return(
        <div className='FooterWrapper'>
            <div className={FooterText1}>© März 2025</div>
            <div className={FooterText2}>Gerrit Helm</div>
        </div>
    )

}

export default Footer;